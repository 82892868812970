import { newTracker, trackPageView } from "@snowplow/browser-tracker";

const snowplow = {
  collector: "com-wetransfer.mini.snplow.net",
  cookieName: "_wt_snowplow.114e",
};

if (process.env.GATSBY_ACTIVE_ENV === "production") {
  snowplow.collector = "snowplow.wetransfer.com";
  snowplow.cookieName = "_wt_snowplow";
}

newTracker("co", `${snowplow.collector}`, {
  bufferSize: 1,
  post: true,
  encodeBase64: false,
  respectDoNotTrack: true,
  userFingerprint: false,
  appId: "ideas_report_hub",
  platform: "web",
  stateStorageStrategy: "cookie",
  contexts: {
    webPage: true,
  },
  cookieName: `${snowplow.cookieName}`,
  discoverRootDomain: true,
});

// Logs when the client route changes
/* eslint-disable-next-line */
export const onRouteUpdate = ({ location }) => {
  if (typeof window !== "undefined") {
    window.dataLayer.push({
      event: "pageview",
      page: {
        path: location.pathname,
      },
    });
  }
  trackPageView();
};
